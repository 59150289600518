<template>
  <date-range-picker
    ref="picker"
    v-model="value"
    :ranges="false"
    show-dropdowns
    auto-apply
    single-date-picker="range"
    class="w-100"
    :locale-data="{ format: 'dd/mm/yyyy' }"
    @update="updateDate"
  />
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  components: { DateRangePicker },
  props: {
    dateRange: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      value: this.dateRange,
    }
  },
  methods: {
    updateDate(value) {
      this.$emit('update', value)
    },
  },
}
</script>
